var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"showBreadcrumbs":true,"topActionButtonPrimary":_vm.editing &&
    _vm.editing.ratingValidations &&
    _vm.editing.ratingValidations.hasValidLossInformation
      ? {
          text: 'Next',
          key: 'next',
          loading: _vm.makingApiRequest || _vm.loading
        }
      : {
          loading: _vm.makingApiRequest || _vm.loading
        },"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.saveQuoteLossData}},[_c('QuoteSteps',{attrs:{"currentStep":7}}),(
      _vm.hasRespondedToAllQuestions &&
        _vm.numberOfClaimsFromLossReport !==
          (_vm.editing ? _vm.editing.numberOfNonWeatherClaims : 0)
    )?_c('custom-alert',{attrs:{"title":"Inconsistent Response","show":true,"dismissible":false,"type":"warning"}},[_c('div',[_c('p',[_vm._v(" Your responses here are inconsistent with information provided earlier about the "),_c('b',[_vm._v(" NUMBER ")]),_vm._v(" of "),_c('b',[_vm._v("NON-WEATHER")]),_vm._v(" or "),_c('b',[_vm._v(" APPLIANCE CLAIMS ")]),_vm._v(" on your property ")]),_c('p',[_vm._v(" The expected number of relevant non weather claims are "),_c('strong',[_vm._v(_vm._s(_vm.editing.numberOfNonWeatherClaims))])]),_c('p',[_vm._v(" Number of non-weather claims indicated on your property are "),_c('strong',[_vm._v(_vm._s(_vm.numberOfClaimsFromLossReport || 0))])]),_c('p',[_vm._v(" Click "),_c('router-link',{attrs:{"to":`${_vm.quoteLink}`}},[_c('b',[_vm._v("here")])]),_vm._v(" to return to the "),_c('router-link',{attrs:{"to":`${_vm.quoteLink}`}},[_vm._v("quote")]),_vm._v(" and make the necessary changes to the "),_c('b',[_vm._v(" NUMBER ")]),_vm._v(" of "),_c('b',[_vm._v("NON-WEATHER")]),_vm._v(" or "),_c('b',[_vm._v(" APPLIANCE CLAIMS ")]),_vm._v(" element "),_c('b',[_vm._v(" OR ")])],1),_c('p',[_vm._v("Stay on this page and edit the losses below")])])]):_vm._e(),(!_vm.hasRatings)?_c('OldRatingCustomAlert',{attrs:{"url":`${_vm.quoteLink}s`}}):_vm._e(),_c('div',{staticClass:"mb-24"},[_c('UnderwriterInfo')],1),(_vm.hasLossReport)?_c('div',_vm._l((_vm.lossInformation),function(lossReport,index){return _c('LossReportViewer',{key:index,staticClass:"mb-4",attrs:{"value":{
        ...lossReport,
        ..._vm.editingLossData[lossReport._id],
        policyType: _vm.editing.lossReport.policyType
      },"label":`MATCH #${index + 1}`,"disabled":(_vm.editing.ratingValidations &&
          _vm.editing.ratingValidations.hasPolicyNumber) ||
          _vm.quoteHasBeenDeleted},on:{"input":function($event){return _vm.lossReportResponseChangedHandler($event, lossReport._id)}}})}),1):_c('div',[_vm._v(" No loss report found for quote . You're good to go. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }